<template>
  <Html :lang="head.htmlAttrs?.lang">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :content="meta.content" :property="meta.property" />
      </template>
    </Head>
  </Html>

  <NuxtPwaManifest />

  <v-app>
    <v-layout>
      <v-main>
        <slot />

        <snackbar-toaster :snackbars="snackbars" />
      </v-main>

      <v-dialog :model-value="cookiePreferences.length === 0" persistent width="600">
        <cookie-message @accept="(preferences) => setCookiePreferences(preferences)" />
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { jwtDecode } from "jwt-decode"
import { useTheme } from "vuetify"

const mainStore = useMainStore()
const authStore = useAuthStore()
const { snackbars } = storeToRefs(mainStore)
const { token } = storeToRefs(authStore)

const { themes, global: globalTheme } = useTheme()

const { theme, cookiePreferences } = useUserPreferences()

const head = useLocaleHead({
  addSeoAttributes: true
})

const setCookiePreferences = (preferences: Array<string>) => {
  cookiePreferences.value = preferences
}

watch(theme, () => {
  if (!theme.value) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const decodedToken = !prefersDark && token.value ? jwtDecode<TokenBody>(token.value) : undefined

    globalTheme.name.value = prefersDark ? 'dark' : decodedToken?.domain === DomainType.CIVIL ? 'civilLight' : 'light'
  }
  if (theme.value && themes.value[theme.value]) {
    globalTheme.name.value = theme.value
  }
}, { immediate: true })

</script>